import React, { useRef } from "react";
import { useSetState, useEffectOnce } from "react-use";
import { navigate } from "gatsby";
import { useMutation } from "react-query";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import { RECAPTCHA_SITE_KEY } from "gatsby-env-variables";

import { LayoutWrapper } from "../components";
import { postRegisterForm, sendEmailOtp } from "../apis";

const EmailOtp = ({ location }) => {
  const mutation = useMutation(({ regionDomain, ...registerForm }) =>
    postRegisterForm(registerForm, regionDomain)
  );
  const resendOtpMutation = useMutation(({ email, regionDomain }) =>
    sendEmailOtp(email, regionDomain)
  );
  const [state, setState] = useSetState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    hasReCaptcha: false,
  });
  const recaptchaRef = useRef(null);

  const otp1Ref = useRef(null);
  const otp2Ref = useRef(null);
  const otp3Ref = useRef(null);
  const otp4Ref = useRef(null);
  const otp5Ref = useRef(null);
  const otp6Ref = useRef(null);
  const otpRefs = [otp1Ref, otp2Ref, otp3Ref, otp4Ref, otp5Ref, otp6Ref];

  const reCaptchaChange = (value) => {
    setState({ hasReCaptcha: !!value });
  };
  const isValid = () => {
    const otp = `${state.otp1}${state.otp2}${state.otp3}${state.otp4}${state.otp5}${state.otp6}`;
    return state.hasReCaptcha && otp.length === 6;
  };
  const onSubmit = async () => {
    if (mutation.isLoading) return;
    const reCaptchaValue = recaptchaRef.current?.getValue();
    const otp = `${state.otp1}${state.otp2}${state.otp3}${state.otp4}${state.otp5}${state.otp6}`;
    if (!reCaptchaValue) {
      // pop error
    } else if (otp.length !== 6) {
      // pop error
    } else {
      try {
        const { key, region, ...registerForm } = location.state;
        const res = await mutation.mutateAsync({
          ...registerForm,
          country: registerForm?.country?.alpha2,
          otp,
          recaptcha: reCaptchaValue,
          regionDomain: region.regionDomain,
        });
        navigate("/register-success");
      } catch (error) {
        // might need to show error
        console.log(error.response);
      }
    }
  };
  const onResendEmail = async () => {
    const { key, region, ...registerForm } = location.state;
    setState({
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    });
    otp1Ref?.current?.focus();
    recaptchaRef.current?.reset();
    const emailForm = { email: registerForm.email };
    try {
      await resendOtpMutation.mutateAsync({
        email: emailForm,
        regionDomain: region.regionDomain,
      });
    } catch (error) {
      console.log(error.response);
    }
  };
  // number 0~9 --> keycode 48~57
  const onInputKeyDown = (e, index) => {
    if (e.keyCode >= 48 && e.keyCode <= 57) {
      setState({
        [`otp${index + 1}`]: e.key,
      });
      setTimeout(() => {
        otpRefs[index + 1]?.current?.focus();
      }, 0);
      if (mutation.isError) {
        mutation.reset();
      }
    } else if (e.keyCode === 8) {
      // backspace (delete)
      setState({
        [`otp${index + 1}`]: "",
      });
      setTimeout(() => {
        otpRefs[index - 1]?.current?.focus();
      }, 0);
      if (mutation.isError) {
        mutation.reset();
      }
    }
  };
  useEffectOnce(() => {
    if (location.state) {
      // has register form
      otp1Ref.current.focus();
    } else {
      navigate("/register", {
        replace: true,
      });
    }
  });
  return (
    <LayoutWrapper
      title={"Check your email"}
      subTitle={
        <>
          Your email verification code was sent to{" "}
          <span style={{ color: "#a5ce39" }}>{location?.state?.email}</span>
        </>
      }
    >
      <div
        className={classNames({
          "email-otp__inputs": true,
          "email-otp__inputs--error":
            mutation.isError && mutation.error.response?.data?.code === 403,
        })}
      >
        <input
          type="text"
          ref={otp1Ref}
          value={state.otp1}
          onKeyDown={(e) => onInputKeyDown(e, 0)}
        />
        <input
          type="text"
          ref={otp2Ref}
          value={state.otp2}
          onKeyDown={(e) => onInputKeyDown(e, 1)}
        />
        <input
          type="text"
          ref={otp3Ref}
          value={state.otp3}
          onKeyDown={(e) => onInputKeyDown(e, 2)}
        />
        <input
          type="text"
          ref={otp4Ref}
          value={state.otp4}
          onKeyDown={(e) => onInputKeyDown(e, 3)}
        />
        <input
          type="text"
          ref={otp5Ref}
          value={state.otp5}
          onKeyDown={(e) => onInputKeyDown(e, 4)}
        />
        <input
          type="text"
          ref={otp6Ref}
          value={state.otp6}
          onKeyDown={(e) => onInputKeyDown(e, 5)}
        />
      </div>
      <div className="email-otp__error">
        {mutation.isError &&
        mutation.error?.response?.data?.message === "OTP expired."
          ? "Invalid OTP"
          : mutation.error?.response?.data?.message}
      </div>
      <div className="email-otp__recaptcha">
        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={reCaptchaChange}
          ref={recaptchaRef}
        ></ReCAPTCHA>
      </div>
      <button
        className={classNames({
          "email-otp__btn": true,
          "email-otp__btn--disabled": !isValid(),
        })}
        onClick={onSubmit}
      >
        {mutation.isLoading ? "Confirming..." : "Confirm"}
      </button>
      <div className="email-otp__hint">
        Haven’t received verification email?{" "}
        <span onClick={onResendEmail}>Resend Email</span>
      </div>
    </LayoutWrapper>
  );
};

export default EmailOtp;
